import * as mixpanel from 'mixpanel-browser';

export function formatPropName(value: string) {
  value = value.replace('prop', '');
  return value[0].toLowerCase() + value.slice(1);
}

export const initAnalytics = () => {
  const isDev = process.env.MODE === 'development';

  mixpanel.init('dbb78e1de4ce62fc63e3c2ec4089bb0a', {
    debug: isDev,
    track_pageview: 'url-with-path',
  });

  if (isDev) {
    mixpanel.identify('development');
  }

  const trackClicks = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    const { dataset } = target;

    const eventName = dataset?.event;

    const properties = Object.fromEntries(
      Object.entries(dataset).map(([name, value]) => {
        if (name.startsWith('prop')) {
          return [formatPropName(name), value];
        } else {
          return [];
        }
      }),
    );

    if (target instanceof HTMLAnchorElement) {
      return mixpanel.track(eventName || 'Link Click', {
        href: target.href,
        text: target.innerText ?? target.ariaLabel,
        ...properties,
      });
    }

    if (!eventName) return;

    return mixpanel.track(eventName, { ...properties });
  };

  addEventListener('click', (e) => trackClicks(e));
};
